import { MvJourney } from '@/lib/strapi-types/MvJourney';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface PopulateObject {
  [key: string]:
    | string
    | boolean
    | PopulateObject
    | {
        sort?: string[];
        filters?: Record<string, unknown>;
        populate?: PopulateObject;
      };
}

interface UseFindOneJourneyProps {
  filters?: Record<string, unknown>;
  populate?: string[] | PopulateObject;
  key?: string;
}

const defaultPopulate = [
  'strongPoint.image',
  'sequences.places',
  'tags.icon',
  'included',
  'notIncluded',
  'GIR',
  'goodToKnows'
];

/**
 * Processes the populate parameter and returns the appropriate value.
 * If the populate parameter is an array, it merges it with the defaultPopulate array.
 * If the populate parameter is an object, it merges it with the defaultPopulate object.
 * If the populate parameter is not provided or is of an invalid type, it returns the defaultPopulate array.
 * @param populate - The populate parameter to process.
 * @returns The processed populate value.
 */
const processPopulate = (populate?: string[] | PopulateObject) => {
  if (Array.isArray(populate)) {
    return [...defaultPopulate, ...populate];
  } else if (typeof populate === 'object' && populate !== null) {
    return {
      ...defaultPopulate.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      ...populate
    };
  } else {
    return defaultPopulate;
  }
};

export async function useFindOneJourney({ filters = {}, populate, key }: UseFindOneJourneyProps) {
  const allPopulate = processPopulate(populate);

  const parsedQuery = stringify({
    populate: allPopulate,
    filters,
    pagination: {
      start: 0,
      limit: 1
    }
  });

  const { data, refresh } = await useFetchWithHead<Strapi4ResponseMany<MvJourney['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.JOURNEYS)}?${parsedQuery}`,
    {},
    `onejourney-${key}`
  );

  return { journey: data.value?.data[0] ?? null, refresh };
}
